<template>
    <div class="problemFound">
        <header>
            <div class="tabs">
                <el-tabs v-model="mainTab" @tab-click="mainTabClick" class="main-tab">
                    <el-tab-pane v-if="subMenu.index_config" label="首页配置" name="0"></el-tab-pane>
                    <el-tab-pane v-if="subMenu.bg_config" label="协同办公配置" name="1"></el-tab-pane>
                </el-tabs>
            </div>
        </header>
        <main>
            <div class="tab-bar">
                <div class="subToolbar">
                    <div class="title" v-if="mainTab == '0'">首页配置列表</div>
                    <div class="title" v-if="mainTab == '1'">协同办公配置列表</div>
                </div>
                <div class="btns">
                    <UploadExcel
                        v-if="mainTab == '0' && userBtns.index_import"
                        :path="'/prod-api/front/cockpitRedirectIndex/importData'"
                        :headers="headers"
                        @uploadEd="getList"
                    />
                    <UploadExcel
                        v-if="mainTab == '1' && userBtns.bg_import"
                        :path="'/prod-api/front/cockpitRedirectButton/importData'"
                        :headers="headers"
                        @uploadEd="getList"
                    />
                </div>
            </div>
            <div class="table">
                <el-table
                    v-if="mainTab == '0'"
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @cell-dblclick="dblclickRow"
                >
                    <el-table-column prop="menuName" label="菜单名称" />
                    <el-table-column prop="orgDivisionName" label="所属行政区域" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="redirectUrl" label="URL" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="msg" label="备注" show-overflow-tooltip> </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button type="text" size="small" @click="editItem(row, '修改首页配置')">修改</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-table
                    v-if="mainTab == '1'"
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @cell-dblclick="dblclickRow"
                >
                    <el-table-column prop="menuName" label="菜单名称" />
                    <el-table-column prop="orgName" label="所属单位" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="redirectUrl" label="URL" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="msg" label="备注" show-overflow-tooltip> </el-table-column>
                    <el-table-column label="操作" width="220">
                        <template #default="{ row }">
                            <el-button type="text" size="small" @click="editItem(row, '修改协同办公配置')"
                                >修改</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getList"
                        @current-change="getList"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
        <IndexEdit v-if="indexEditDialog" :propsData="indexEditData" :title="title" @close="indexEditClose" />
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import IndexEdit from './indexEdit';
import UploadExcel from '@/components/uploadExcel';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
let headers = {
    Authorization: localStorage.token,
};
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
});
let total = ref(0);
let mainTab = ref('0');

let tableData = ref([]);
let indexEditDialog = ref(false);
let indexEditData = ref({});
let title = ref('');
function editItem(row = null, name) {
    indexEditDialog.value = true;
    indexEditData.value = JSON.parse(JSON.stringify(row));
    title.value = name;
}
function indexEditClose() {
    indexEditDialog.value = false;
    getList();
}
const mainTabClick = (vm) => {
    mainTab.value = vm.props.name;

    getList();
    router.replace({
        query: { tab: vm.props.name },
    });
};
// 指标删除
function handleDel(row) {
    proxy.$api.problemFound.delete(row.problemId).then((res) => {
        ElMessage({
            message: '删除成功',
            type: 'success',
        });
        getList();
    });
}
function openDetail(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '查看',
            id: row.unitId,
        },
    });
}
function addQue() {}
function dblclickRow(row) {
    openDetail(row);
}
async function getList() {
    let url = ['/front/cockpitRedirectIndex/list', '/front/cockpitRedirectButton/list'];
    const res = await axios({
        url: url[mainTab.value],
        method: 'get',
        params: query.value,
    });
    if (res) {
        console.log(res);
        tableData.value = res.records;
        total.value = res.total;
    }
}
function search() {
    query.value.page = 1;
    getList();
}
function resize() {
    query.value = {
        page: 1,
        size: 15,
    };
    getList();
}

// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
//获取subMenu权限
const subMenu = computed(() => {
    return store.state.user.sub_menu;
});
watch(() => {});
onMounted(() => {
    getList();
    mainTab.value = route.query.tab || '0';
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.problemFound {
    height: calc(100% - 90px);
    header {
        height: 64px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 10px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .tab-bar {
            display: flex;
            // align-items: center;
            justify-content: space-between;
            .subToolbar {
                text-align: left;
                margin-bottom: 16px;
                .el-button {
                    margin-right: 8px;
                }
            }
            .btns {
                text-align: right;
            }
        }

        .table {
            height: calc(100% - 70px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                padding-top: 10px;
                text-align: right;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
    .status {
        display: inline-block;
        width: 68px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        &.status2 {
            background: rgba(100, 183, 255, 0.2);
            color: #1492ff;
        }
        &.status3 {
            background: rgba(68, 203, 87, 0.2);
            color: #44cd40;
        }
        &.status1 {
            background: rgba(255, 176, 81, 0.2);
            color: #ff8b00;
        }
        &.status4 {
            background: rgba(255, 127, 127, 0.2);
            color: #ff5c5c;
        }
        &.status0 {
            background: rgba(216, 216, 216, 0.2);
            color: #999999;
        }
        &.status5 {
            background: rgba(85, 230, 193, 0.2);
            color: rgb(85, 230, 193);
        }
        &.status6 {
            background: rgba(179, 55, 113, 0.2);
            color: rgb(179, 55, 113);
        }
        &.status7 {
            background: rgba(189, 197, 129, 0.2);
            color: rgb(189, 197, 129);
        }
    }
}
</style>
